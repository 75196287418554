import React from 'react'
import { Link } from "gatsby";
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section id='not-found' className="section">
      <h1>404</h1>
      <p>Perbacco!<br/> Questa pagina non esiste.</p>
      <Link to='/' rel="noopener noreferrer" target='_blank' className="button btn-primary">
        Torna alla home
      </Link>
    </section>
  </Layout>
)

export default NotFoundPage
